{
  "name": "@thrivesoft/thrive-dashboard",
  "version": "3.11.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -c development --port 4300",
    "build:dev": "ng build -c development",
    "build:prod": "ng build -c production",
    "postbuild": "mv ./dist/thrive-dashboard/browser/* ./dist/thrive-dashboard && rm -rf ./dist/thrive-dashboard/browser",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "testcafe",
    "security-audit-dashboard": "./scripts/audit.sh",
    "ci-e2e": "testcafe all e2e/testcafe/dashboard/**/*.e2e.ts -r spec",
    "ci-update-dist-environment": "ts-node -O '{\"module\": \"commonjs\"}' build/set-environment.ts",
    "ci-update-firebase-json": "cp environments/firebase.$CI_ENVIRONMENT_NAME.json firebase.json && thrive-security-headers inject-csp-firebase -c firebase.json",
    "github-ci-update-dist-environment": "ts-node -O '{\"module\": \"commonjs\"}' build/set-github-environment.ts",
    "github-ci-update-firebase-json": "cp environments/firebase.$BUILD_ENV.json firebase.json && thrive-security-headers inject-csp-firebase -c firebase.json",
    "github-ci-generate-version-number": "ts-node -O '{\"module\": \"commonjs\"}' build/generate-github-version.ts",
    "ci-generate-version-number": "ts-node -O '{\"module\": \"commonjs\"}' build/generate-version.ts",
    "scope-gen": "ts-node -O '{\"module\": \"commonjs\"}' build/scope-gen.ts",
    "swagger-gen": "ng-swagger-gen && npm run scope-gen",
    "localisation-gen": "ts-node -O '{\"module\": \"commonjs\"}' build/localisation/gen-localisation.ts",
    "serve:unsafe": "ng serve -c development --host 0.0.0.0 --disable-host-check",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@angular-material-extensions/password-strength": "^16.0.0",
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.8",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/platform-server": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@angular/service-worker": "^18.2.8",
    "@ng-web-apis/common": "^4.11.1",
    "@ng-web-apis/intersection-observer": "^4.11.1",
    "@ngbracket/ngx-layout": "18.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@thrivesoft/eslint-config-thrive": "^2.1.0",
    "@thrivesoft/gocore-web": "^0.97.0",
    "angularx-qrcode": "^18.0.2",
    "base32-encode": "^2.0.0",
    "echarts": "^5.5.1",
    "firebase": "^10.14.1",
    "google-libphonenumber": "^3.2.26",
    "i18n-iso-countries": "^7.12.0",
    "jose": "^5.9.4",
    "moment-timezone": "^0.5.43",
    "ngx-echarts": "^18.0.0",
    "ngx-ellipsis": "^5.0.1",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-pipes": "~3.2.2",
    "rxjs": "^7.5.1",
    "tslib": "^2.3.1",
    "validate.js": "0.13.1",
    "zone.js": "^0.14.10"
  },
  "overrides": {
    "@angular-material-extensions/password-strength": {
      "@angular/common": "18.2.8",
      "@angular/cdk": "18.2.8",
      "@angular/core": "18.2.8",
      "@angular/forms": "18.2.8",
      "@angular/material": "18.2.8"
    }
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-devkit/core": "^18.2.8",
    "@angular/build": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-conventional": "^19.5.0",
    "@thrivesoft/security-headers": "^1.16.0",
    "@types/gapi": "^0.0.47",
    "@types/gapi.client": "^1.0.8",
    "@types/gapi.client.drive-v3": "^0.0.5",
    "@types/google-libphonenumber": "^7.4.23",
    "@types/google.accounts": "^0.0.15",
    "@types/google.picker": "^0.0.42",
    "@types/jest": "^29.5.5",
    "@types/node": "^20.6.3",
    "@types/validator": "13.12.2",
    "angular-eslint": "18.3.1",
    "angular-http-server": "^1.10.0",
    "angular-testcafe": "^4.0.0",
    "codelyzer": "^6.0.2",
    "eslint": "^9.9.1",
    "html-webpack-plugin": "^5.6.0",
    "husky": "^9.1.6",
    "jest": "^29.7.0",
    "ng-swagger-gen": "^2.3.1",
    "prettier": "^3.3.3",
    "request": "^2.88.2",
    "request-promise-native": "^1.0.9",
    "testcafe": "^3.6.2",
    "testcafe-reporter-xunit": "^2.1.0",
    "ts-jest": "^29.1.1",
    "ts-node": "^10.9.1",
    "typescript": ">=5.4.0 and <5.6.0",
    "typescript-eslint": "8.8.1"
  },
  "engines": {
    "node": ">=18.19.0"
  }
}
